import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { resetPassword } from "../../../services/AuthService";
import { useNavigate } from "react-router-dom";

// global css
import GlobalStyles from "../../../styles/GlobalStyles";

// assets
import LoginImage from "../../../../assets/images/login-bg.png";
import key from "../../../../assets/icons/key.svg";

// libs
import {
  CssBaseline,
  TextField,
  Box,
  Grid,
  Typography,
  Snackbar,
  Link,
  Backdrop,
  CircularProgress,
  Button,
} from "@mui/material";

// common
import { Logo, Alert } from "../../../components/common";

import {
  ArrowBack as ArrowBackIcon,
  MailOutline as MailOutlineIcon,
} from "@mui/icons-material";

const ResetPassword = () => {
  const [openSnack, setOpenSnack] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [snackVariant, setSnackVariant] = useState("success");
  const [isSent, setIsSent] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [counter, setCounter] = useState(20);
  const [email, setEmail] = useState("");
  const [openLoader, setOpenLoader] = useState(false);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email_address: "",
    },
  });

  // submit form reset password
  const onSubmit = (data) => {
    setOpenLoader(true);
    resetPassword(data)
      .then(function (rs) {
        setEmail(data.email_address);
        setIsSent(true);
        setOpenLoader(false);
      })
      .catch(function (error) {
        const dataMessage = error.response.data.message.email_address;
        const dataErrors = error.response.data.errors;
        for (const errorsKey in dataErrors) {
          if (!dataErrors.hasOwnProperty(errorsKey)) continue;
          dataMessage += "\r\n" + dataErrors[errorsKey];
        }
        setErrMsg(dataMessage);
        setOpenSnack(true);
        setSnackVariant("error");
        setIsSent(false);
      });
  };

  // close snackbar
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleResend = () => {
    setOpenLoader(true);
    resetPassword({ email_address: email }).then(function (rs) {
      setErrMsg(rs.data.message);
      setOpenSnack(true);
      setSnackVariant("success");
      setDisabled(true);
      setOpenLoader(false);

      const interval = setInterval(() => {
        setCounter((counter) => counter - 1);
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        setCounter(20);
        setDisabled(false);
      }, 20000);
    });
  };

  return (
    <Grid sx={{ backgroundColor: "#12355B" }}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${LoginImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "dark"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} elevation={6} square="true">
          <Box
            sx={{
              my: 8,
              mx: 4,
              padding: 7,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Logo />
            <Box sx={{ mt: 8, textAlign: "left" }}>
              {!isSent ? (
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Typography variant="h5" sx={{ mb: 3 }}>
                    <Box
                      component="img"
                      sx={{
                        height: "25px",
                        width: "25px",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 2,
                      }}
                      src={key}
                    />
                    Forgot password
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    mb=""
                    sx={{ lineHeight: "18.75px" }}
                  >
                    No worries, we’ll send you reset instructions.
                  </Typography>
                  <Controller
                    name="email_address"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        margin="normal"
                        fullWidth
                        id="email_address"
                        label="Enter your email"
                        name="email_address"
                        autoFocus
                        variant="filled"
                        inputProps={{ autoComplete: "none" }}
                        error={!!errors.email_address}
                        helperText={
                          errors.email_address && "Email address is required."
                        }
                        {...field}
                      />
                    )}
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Reset Password
                  </Button>
                </Box>
              ) : (
                <Box>
                  <Typography
                    variant="h7"
                    alignItems="center"
                    sx={{ mb: 3 }}
                    display="flex"
                  >
                    <MailOutlineIcon />
                    <Typography variant="h5" ml={2}>
                      Check your email
                    </Typography>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{ lineHeight: "18.75px" }}
                  >
                    We sent a password reset link to {email}
                  </Typography>

                  <Typography
                    variant="subtitle2"
                    mt={4}
                    sx={{ lineHeight: "18.75px" }}
                  >
                    Didn’t receive the email?
                  </Typography>
                  <Button
                    onClick={() => handleResend()}
                    disabled={disabled}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 1, mb: 2 }}
                  >
                    Resend email {disabled ? `(${counter})` : ""}
                  </Button>
                </Box>
              )}
            </Box>

            <Link
              sx={{ color: GlobalStyles.white, display: "flex", mt: 2 }}
              alignItems="center"
              justifyContent="center"
              href="/login"
            >
              <ArrowBackIcon />
              <Typography variant="body2" ml="5px">
                Back to login
              </Typography>
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackVariant}
          sx={{ width: "100%" }}
        >
          {errMsg}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};

export default ResetPassword;
